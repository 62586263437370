<template>
  <div class="popup_sub_container">
    <h2>VIEW / EDIT BRANCH</h2>
    <i class="closeme" @click="closeModal">X</i>
    <section v-if="unit_id">
      <div class="jobs_table sp">
        <div class>
        <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
          <section>
            <div class="form_section1">
              <table>
                <tr>
                  <td colspan="3">
                    <div class="colspan_flex_adj">
                    <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                      <div class="label_and_element_wrapper">
                        <label>
                          Branch Name
                          <span>*</span>
                        </label>
                        <input type="text" placeholder="Name Character Limit 30" v-model="unit.name" :maxlength="max"  :class="errors[0] ? 'warning-border':''" :disabled="!is_editable"/>
                      </div>
                    </ValidationProvider>

                    <ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }" slim>
                      <div class="label_and_element_wrapper">
                        <label>
                          Branch Code
                          <span>*</span>
                        </label>
                        <input type="text" placeholder="Code Character Limit 30" v-model="unit.branch_id" :maxlength="max"  :class="errors[0] ? 'warning-border':''" :disabled="!is_editable"/>
                      </div>
                    </ValidationProvider>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ValidationProvider name="hub" rules="required" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Hub/Mailroom
                        <span>*</span>
                      </label>
                      <select v-model="unit.division_id" @click="fetchDivision" :disabled="!is_editable" :class="errors[0] ? 'warning-border':''">
                        <option value="">Select</option>
                        <option v-for="(division,i) in divisions" :key="'division'+i" :value="division.id">{{division.name}}</option>
                      </select>
                    </div>
                    </ValidationProvider>
                  </td>
                 <!--  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Hub
                        <span>*</span>
                      </label>
                      <select v-model="unit.hub_id" @click="fetchHub" :disabled="!is_editable">
                        <option value="">Select</option>
                        <option v-for="(hub,i) in hubs" :key="'hub'+i" :value="hub.id">{{hub.name}}</option>
                      </select>
                    </div>
                  </td> -->
                  <td>
                    <ValidationProvider name="cost" rules="required" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Cost Center
                        <span>*</span>
                      </label>
                      <select v-model="unit.cost_center_id" @click="fetchCostCenter" :disabled="!is_editable" :class="errors[0] ? 'warning-border':''">
                        <option value="">Select</option>
                        <option v-for="(cost,i) in cost_centers" :key="'cost'+i" :value="cost.id">{{cost.name}}</option>
                      </select>
                    </div>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <div class="colspan_flex_adj">
                    <ValidationProvider name="desk" rules="required|max:60" v-slot="{ errors }" slim>
                      <div class="label_and_element_wrapper">
                        <label>
                          Desk
                          <span>*</span>
                        </label>
                        <input type="text" placeholder="Address 1 Character Limit 60" v-model="unit.desk" :maxlength="max2"  :class="errors[0] ? 'warning-border':''" :disabled="!is_editable"/>
                      </div>
                    </ValidationProvider>
                    
                    <ValidationProvider name="floor" rules="required|max:60" v-slot="{ errors }" slim>
                      <div class="label_and_element_wrapper">
                        <label>
                          Floor
                          <span>*</span>
                        </label>
                        <input type="text" placeholder="Address 2 Character Limit 60" v-model="unit.floor" :maxlength="max2"  :class="errors[0] ? 'warning-border':''" :disabled="!is_editable"/>
                      </div>
                    </ValidationProvider>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <div class="colspan_flex_adj">
                    <ValidationProvider name="address_1" rules="required|max:60" v-slot="{ errors }" slim>
                      <div class="label_and_element_wrapper">
                        <label>
                          Address 1
                          <span>*</span>
                        </label>
                        <input type="text" placeholder="Address 1 Character Limit 60" v-model="unit.address_1" :maxlength="max2"  :class="errors[0] ? 'warning-border':''" :disabled="!is_editable"/>
                      </div>
                    </ValidationProvider>

                    <ValidationProvider name="address_2" rules="required|max:60" v-slot="{ errors }" slim>
                      <div class="label_and_element_wrapper">
                        <label>
                          Address 2
                          <span>*</span>
                        </label>
                        <input type="text" placeholder="Address 2 Character Limit 60" v-model="unit.address_2" :maxlength="max2"  :class="errors[0] ? 'warning-border':''" :disabled="!is_editable"/>
                      </div>
                    </ValidationProvider>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Suburb
                      </label>
                      <input type="text" placeholder="Suburb Character Limit 30" v-model="unit.suburb" :maxlength="max" class :disabled="!is_editable"/>
                    </div>
                  </td>
                  <td>
                    <ValidationProvider name="city" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        City
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="City Character Limit 30" v-model="unit.city" :maxlength="max"  :class="errors[0] ? 'warning-border':''" :disabled="!is_editable"/>
                    </div>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider name="state" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        State
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="State Character Limit 30" v-model="unit.state" :maxlength="max"  :class="errors[0] ? 'warning-border':''" :disabled="!is_editable"/>
                    </div>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ValidationProvider name="country" rules="required|max:63" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Country
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Country Character Limit 30" v-model="unit.country" :maxlength="max"  :class="errors[0] ? 'warning-border':''" :disabled="!is_editable"/>
                    </div>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider name="postal_code" rules="required|max:10" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Postal Code
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Postal Code Character Limit 10" v-model="unit.postal_code" :maxlength="max1"  :class="errors[0] ? 'warning-border':''" :disabled="!is_editable"/>
                    </div>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider name="phone" rules="required|max:20" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Phone Number
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Phone Character Limit 20" v-model="unit.phone" :maxlength="max3"  :class="errors[0] ? 'warning-border':''" :disabled="!is_editable"/>
                    </div>
                    </ValidationProvider>
                  </td>
                </tr>
              </table>
            </div>
          </section>
        </ValidationObserver>

          <section class="centered">
            <a class="link_bt bt_cancel" @click="closeModal">CANCEL</a>
            <a class="link_bt bt_save" @click="updateBranch" :disabled="update_btn.disabled" v-if="is_editable">{{update_btn.value}}</a>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "EditBranch",
  created(){
    this.fetchUnit();
    this.fetchCostCenter();
    this.fetchDivision();
    this.fetchHub();
  },
  data() {
    return {
      max:30,
      max2:60,
      max1:10,
      max3:20,
      unit:{
        name: "",
        branch_id: "",
        cost_center_id: "",
        division_id: "",
        hub_id: "",
        desk: "",
        floor: "",
        address_1: "",
        address_2: "",
        suburb: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        phone: ""
      },
      cost_centers:[],
      divisions:[],
      hubs:[],
      update_btn:{
        disabled:false,
        value:"UPDATE"
      }
    };
  },
  methods:{
    closeModal() {
      this.$emit("closeModal");
    },
    fetchUnit(){
      this.axios.get("api/branch/view/branchId/"+this.unit_id)
      .then(response => {
        this.unit = response.data.branches;
      })
      .catch(error => {
        console.log(error);
      })
    },
    fetchCostCenter(){
      this.axios.get("/api/cost/")
      .then(response => {
        this.cost_centers = response.data.cost_centers;
      })
      .catch(error => {
        this.toast.error(error);
      });
    },
    fetchDivision(){
      this.axios.get("/api/division/")
      .then(response => {
        this.divisions = response.data.divisions;
      })
      .catch(error => {
        this.toast.error(error);
      });
    },
    fetchHub(){
      this.axios.get("/api/hub/")
      .then(response => {
        this.hubs = response.data.hubs;
      })
      .catch(error => {
        this.toast.error(error);
      });
    },
    updateBranch(){
      this.$refs.observer.validate();
      this.update_btn.disabled = true;
      this.update_btn.value="UPDATING...";
      this.axios.put("api/branch/"+this.unit_id,this.unit).then(response => {
        this.toast.success(response.data.msg);
        this.update_btn.disabled = false;
        this.update_btn.value="UPDATE";
        this.closeModal();
      })
      .catch(error => {
        this.update_btn.disabled = false;
        this.update_btn.value="UPDATE";
        console.log(error);
      });
    }
  },
  props:{
    unit_id:String,
    is_editable:{
      type:Boolean,
      default:true
    }
  }
};
</script>
<style lang="scss">
.popup_sub_container {
  padding: 20px;
  width: 100%;
  margin: auto;

  background: #fff;
}
.popup_sub_container > section {
  padding: 20px 0 0 0;
}

h3.sub_head {
  font-size: 20px;
  font-weight: 600;
  padding: 0;
}
.preferred_hide {
  display: none;
}
.chk_prefered {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 0 20px;
}
.chk_prefered:checked + hr + .preferred_hide {
  display: block;
}

.title_image {
  display: block;
  margin: auto;
}

.form_section1 {
  width: 100%;
  height: auto;
  padding: 20px;
  background: #ececec;
  margin: 20px 0;
}

i.closeme {
  width: 30px;
  height: 30px;
  background: #000;
  color: #06A5ED;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  border-radius: 100px;
  display: block;
  font-style: normal;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
</style>
<style scoped>
.jobs_table table {
  border: 0;
}
.jobs_table table tr td {
  padding: 0;
  border: 0;
}
</style>